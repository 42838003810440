<template>
    <Loader v-if="loading"></Loader>
    <div v-else>
        <div class="bg-white card">
            <div class="fl-te-c mb-3">
                <h2>Order Details</h2>
                <btn type="button" size="sm" @click="Redirect" text="Back"/>
            </div>
            <div class="fl-eq-t mb-3">
                <div>
                    <h6>Order ID</h6>
                    <p>{{ order_details[0].id }}</p>
                </div>
                <div>
                    <h6>Quantity</h6>
                    <p>{{ order_details[0].total_quantity }}</p>
                </div>
                <div>
                    <h6>Amount</h6>
                    <p>₹
                        <cur :value="order_details[0].total_amount"></cur>
                    </p>
                </div>

            </div>
        </div>
        <div class="bg-white card mt-2">
            <div class="fl-eq-t mb-3">
                <div>
                    <h6>Customer Name</h6>
                    <p>{{ user[0].name }}</p>
                    <h6>Contact Number</h6>
                    <p>{{ user[0].phone }}</p>
                </div>
                <div>
                    <h6>Address</h6>
                    <p>{{ address[0].name }}<br>
                        {{ address[0].address_line_1 }}<br>
                        {{ address[0].address_line_2 }}<br>
                        {{ address[0].district }},
                        {{ address[0].state }}<br>
                        {{ address[0].country }},
                        {{ address[0].pin_code }}<br></p>
                </div>
            </div>

        </div>
        <div class="bg-white card mt-2">
            <div v-for="(product, index) in order_items" v-bind:key="index" class="cart-item fl-x-c mb-2 py-2 bb-1">
                <div class="mr-2">
                    <img src="../../../assets/img/No_image_available.svg" :alt="product.name" v-if="!product.img"
                         width="40px"/>
                    <img :src="product.img" alt="Image" class="product-img" height="40" width="40" v-else>
                </div>
                <div class="fl-y-l fl-grow">
                    <b v-html="product.product_name"></b>
                    <div class="fl-te-c">
                        <div>
                            <cur :value="product.unit_price"/>
                            x {{ product.quantity }} = <b>
                            <cur class="text-primary" :value="product.total_price"/>
                        </b></div>
                    </div>
                </div>
<!--                <div class="fl-y-c">-->
                    <!--                <c-btn border-radius="0" color="danger" icon="fa fa-trash" @click="DeleteItem(product)"/>-->
<!--                </div>-->
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';
// import CardButton from '../../../components/CardButton';
import Loader from '../../../views/loader/loader';
import Cur from '../../../components/Currency';

export default {

    name: 'orderDetails',
    components: {
        Cur,
        // 'c-btn': CardButton,
        Loader
    },
    data () {
        return {
            loading: false,
            order_items: [],
            order_details: [],
            user: [],
            address: []

        };
    },
    beforeMount () {
        this.loadData();
    },
    methods: {
        async loadData () {
            this.loading = true;
            const response = await axios.get(urls.shopOrder.orderdetails + '?id=' + this.$route.params.id);
            this.loading = false;
            this.setData(response);
        },
        setData (response) {
            console.log('response', response);
            this.order_items = response.data.data;
            this.user = response.data.shopdata.user;
            this.address = response.data.shopdata.user_address;
            this.order_details = response.data.shopdata.order_details;
        },
        Redirect () {
            this.$router.push({ path: '/admin/orders/' });
        }
        // async DeleteItem(rowData) {
        //     const confirm = await this.$bvModal.msgBoxConfirm('Are you sure ?');
        //     if (confirm) {
        //         const params = {id: rowData.id, order_id: rowData.order_id};
        //         const response = await axios.form(urls.shopOrder.RemoveItem, params);
        //         console.log(response);
        //     }
        // },
        // StatusUpdate() {
        //     alert();
        // }
    }
};
</script>

<style scoped>

</style>
